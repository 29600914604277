<script setup lang="ts">
import DefaultLayout from '@/Layouts/DefaultLayout.vue'
import TheHeader from '@/Components/TheHeader.vue'
import { Author } from '@/Interfaces/Author'
import { Breadcrumb } from '@/Components/the-breadcrumbs/interfaces/Breadcrumb'
import TheHeroAuthor from '@/Components/the-hero/TheHeroAuthor.vue'
import TheHero from '@/Pages/the-articles/components/TheHero.vue'
import TheBreadcrumbs from '@/Components/the-breadcrumbs/TheBreadcrumbs.vue'
import TheImageWebpBig from '@/Components/the-image/TheImageWebpBig.vue'
import TheSlpTableContent from '@/Pages/the-slp/components/TheSlpTableContent.vue'
import TheSlpHowWorks from '@/Pages/the-slp/components/TheSlpHowWorks.vue'
import TheSlpDistrictsList from '@/Pages/the-slp/components/TheSlpDistrictsList.vue'
import TheSlpMoreGuides from '@/Pages/the-slp/components/TheSlpMoreGuides.vue'
import TheAuthorBox from '@/Components/the-author/TheAuthorBox.vue'
import TheSlpSidebarHwBanner from '@/Pages/the-slp/components/TheSlpSidebarHwBanner.vue'

import TheSidebarElementAbout from '@/Components/the-sidebar/SidebarElementAbout.vue'
import TheSidebarElementWorldpackers from '@/Components/the-sidebar/SidebarElementWorldpackers.vue'
import TheSidebarElementInsurance from '@/Components/the-sidebar/SidebarElementInsurance.vue'

import { User } from '@/types'
import TheSlpSidebarTopHostel from '@/Pages/the-slp/components/TheSlpSidebarTopHostel.vue'
import TheSlpContent from '@/Pages/the-slp/components/TheSlpContent.vue'
import PageEditLink from '@/Components/PageEditLink.vue'
import HiddenGemz from '@/Components/hidden-gemz/HiddenGemz.vue'
import { HiddenGemzType } from '@/Interfaces/Common'
import { Image } from '@/Pages/the-country/interfaces/City'
import { onMounted, onUnmounted, ref, provide, computed } from 'vue'
import AppModal from '@/Components/the-modal/AppModal.vue'
import {
    isUserLogged,
    loginEndpoint,
    setAuthorizationStatus,
    toggleIsUserLogged,
    setUserInfo,
    clearAuthorizationFormData,
    errorStatusFromServer
} from '@/Composables/authorization'
import {
    setToastShow,
    toastData,
    toastShow,
    toastShowAtAdding
} from '@/Composables/toastLogic'
import {
    setUserWishListLocationsIDs,
    wishlistsIsActiveEndpoint,
    isLoadingRequestWishlist,
    closeWishlistModal,
    showWishlistModal
} from '@/Composables/wishlistLogic'

import TwoFieldsLoginModalContent from '@/Components/the-login/TwoFieldsLoginModalContent.vue'
import SignupSidebar from '@/Components/SignupSidebar.vue'
import { Location } from '../the-home/interfaces/Search'
import { useEscClose } from '@/Composables/use-esc-close'
import TheToast from '@/Components/toast/TheToast.vue'
import CcRelatedArticles from '@/Pages/content-cluster/RelatedArticles.vue'
import { CcRelatedArticle, CityInfo as ccCityInfoType } from '@/Pages/content-cluster/interfaces/ContentCluster'

const { hero, slp } = defineProps<{
    user: User | null
    breadcrumbs: Breadcrumb[]
    slp: {
        title: string
        city: {
            name: string
            url: string
        }
        author: Author
    }
    shortCodesData: []
    hero: {
        title: string
        author: Author
        publishedDate: string
        image: Image
    }
    content: string
    districts: []
    moreGuides: []
    hwLink: string
    topHostel: {
        name: string
        title: string
        image: Image
        url: string
        rawUrl: string
    }
    editLink?: string
    hiddenGemz?: HiddenGemzType[]
    location?: Location
    introParagraph: string
    contentCluster: CcRelatedArticle[]
}>()

onMounted(() => {
    window.addEventListener('keydown', useEscClose(closeWishlistModal))
})

const ccCityInfo = computed((): ccCityInfoType | undefined => {
    return {
        city: slp.city.name,
        url: slp.city.url
    }
})

const enteredEmail = ref('')

const updateEnteredEmail = (event: Event): void => {
    const target = event.target as HTMLInputElement

    enteredEmail.value = target.value
}

const enteredPassword = ref('')

const updateEnteredPassword = (event: Event): void => {
    const target = event.target as HTMLInputElement

    enteredPassword.value = target.value
}

const rememberMeCheckbox = ref(false)

const updateRememberMeCheckbox = (event: Event): void => {
    const target = event.target as HTMLInputElement

    rememberMeCheckbox.value = target.checked
}

const clearInputs = (): void => {
    enteredPassword.value = ''
    enteredEmail.value = ''
    rememberMeCheckbox.value = false
}

const submitModalLoginForm = (): void => {

    const request = {
        url: '/login',
        username: enteredEmail.value,
        password: enteredPassword.value,
        remember: rememberMeCheckbox.value
    }

    loginEndpoint(request, (data) => {
        setAuthorizationStatus(data)

        if (data.user) {
            toggleIsUserLogged(true)

            setUserInfo(data.user)

            closeWishlistModal()

            enteredPassword.value = ''

            enteredEmail.value = ''

            clearAuthorizationFormData()

            const request = {
                url: '/wishlists/isActive'
            }

            wishlistsIsActiveEndpoint(request, (data) => {

                if (data.listings) {
                    setUserWishListLocationsIDs(data.listings)
                }
            })
        }
    })
}

const closeToast = (show: boolean): void => {
    setToastShow(show)
}

provide('mapTitle', hero.title)

onUnmounted(() => {
    clearAuthorizationFormData()

    window.removeEventListener('keydown', useEscClose(closeWishlistModal))
})

</script>

<template>
    <DefaultLayout>
        <template #header>
            <TheHeader
                :location="location"
                with-search
            />
        </template>
        <TheHero>
            <template #image>
                <TheImageWebpBig :image="hero.image" class="bg-image" />
            </template>

            <TheBreadcrumbs
                :breadcrumbs="breadcrumbs"
                theme="light"
            />

            <h1 class="h2 text-center text-white mb-0">
                {{ hero.title }}
            </h1>
            <TheHeroAuthor :author="hero.author" :published-date="hero.publishedDate" />

            <p class="text-sm">
                (First-Hand Travel Experience & Price Comparison)
            </p>

            <PageEditLink v-if="user?.canEdit && editLink" :edit-link="editLink" class="text-white" />
        </TheHero>
        <section class="container">
            <div class="row flex-column flex-lg-row">
                <div class="col-12 col-lg-8 pb-5 mb-5 slp-wrap">
                    <!--todo: create table content-->

                    <p v-html="introParagraph" />

                    <TheSlpTableContent />

                    <TheSlpContent :content="content" :short-codes-data="shortCodesData" />

                    <TheSlpHowWorks :city="slp.city" />

                    <TheSlpDistrictsList :items="districts" />

                    <TheSlpMoreGuides :city="slp.city.name" :items="moreGuides" />

                    <TheAuthorBox :author="hero.author" />
                </div>
                <div class="slp-sidebar col-12 col-lg-4">
                    <TheSidebarElementAbout />

                    <TheSidebarElementInsurance />

                    <template v-if="hiddenGemz && hiddenGemz.length">
                        <HiddenGemz
                            v-for="(hg, key) in hiddenGemz"
                            :key
                            :="hg"
                            :user="user"
                            class="p-3 mb-5"
                        />
                    </template>

                    <TheSlpSidebarHwBanner :hw-link="hwLink" />

                    <TheSidebarElementWorldpackers />

                    <!--TheSlpSidebarVideo /!-->

                    <SignupSidebar class="mb-5" />

                    <TheSlpSidebarTopHostel :="topHostel" />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <CcRelatedArticles v-if="ccCityInfo" :articles="contentCluster" :city-info="ccCityInfo" />
                </div>
            </div>
        </section>

        <AppModal
            :show="showWishlistModal"
            class="login-modal-the-slp"
        >
            <TwoFieldsLoginModalContent
                :entered-email="enteredEmail"
                :entered-password="enteredPassword"
                :remember-me-checkbox="rememberMeCheckbox"
                :error-status-from-server="errorStatusFromServer"
                :is-user-logged="isUserLogged"
                :is-loading="isLoadingRequestWishlist"
                @clear-inputs="clearInputs"
                @clear-authorization-form-data="clearAuthorizationFormData"
                @close-modal="closeWishlistModal"
                @submit-modal-login-form="submitModalLoginForm"
                @update-entered-email="updateEnteredEmail"
                @update-entered-password="updateEnteredPassword"
                @update-remember-me-checkbox="updateRememberMeCheckbox"
            />

            <template #close-icon>
                <span class="close-modal-btn">&times;</span>
            </template>
        </AppModal>

        <TheToast
            v-if="isUserLogged"
            title="Listing"
            class="listings-toast"
            :content="toastData?.name"
            :link="toastData?.path"
            :duration="3000"
            :toast-show="toastShow"
            :toast-show-at-adding="toastShowAtAdding"
            @close-toast="closeToast"
        />
    </DefaultLayout>
</template>

<style lang="scss" scoped>
:deep(.header-line) {
    height: 0;
}
</style>

<style lang="scss">
.login-modal-the-slp {

    .app-modal {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1600;
    }

    .app-modal-close-btn {

        &:hover {
            .close-modal-btn {
                opacity: 1;
            }
        }
    }

    .app-modal-dialog {
        width: 100%;
        max-width: 100%;
        padding: 16px 8px;
        background-color: transparent;
        box-shadow: none;
        min-height: calc(100% - 1rem);
        display: flex;
        align-items: center;

        @media (min-width: 576px) {
            margin: 1.75rem auto;
            min-height: calc(100% - 3.5rem);
            padding: 0;
            max-width: 500px;
        }
    }

    .app-modal-close-btn {
        top: 36px;
        right: 26px;

        .close-modal-btn {
            color: #000;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            opacity: 0.5;
            text-shadow: 0 1px 0 #fff;
            transition: color 0.3s, opacity 0.3s;
            display: flex;
        }
    }
}
</style>